



<header class="header">
<span (click)="slideInPanelService.isVisible = true">
<!--  <img class="menuIcon" src="assets/images/menu.png"/>-->
</span>
</header>
<article>
  <h1>May<strong>Byte</strong></h1>
  <h2>Software development</h2>
  <h3>Contact us:</h3>
  <p><img class="emailIcon" src="assets/images/email1.png"/> info@maybyteservices.nl</p>
  <p>
<!--    <img class="indexImg" src="assets/images/index.png"/>-->
  </p>
</article>
<footer>© 2013 - {{year}} MayByte Services v 3.0  - KvK-nummer: 53732308 | Alle rechten voorbehouden </footer>
<app-slide-in-panel>
  <a routerLink="/over-ons">Over ons</a>
  <a routerLink="/heroes">Heroes</a>
</app-slide-in-panel>


